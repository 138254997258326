<template>
  <div>
    <v-flex class="field">
      <span>Have a Coupon?</span>
      <v-text-field label
                    placeholder="E.g #HAPPY2023"
                    :error-messages="errorMessages"
                    v-model="code">
      </v-text-field>
    </v-flex>
  </div>
</template>

<script>
import _ from 'lodash';
import moment from 'moment';

export default {
  props: {
    hotelId: {
      type: String,
      default: '',
    },
    roomId: {
      type: String,
      default: '',
    },
    duration: {
      type: String,
      default: '3h',
    },
    checkinDate: {
      type: String,
    },
    checkinTime: {
      type: String,
    },
  },
  emits: ['input', 'onDiscountValueChanged'],
  data() {
    return {
      code: '',
      errorMessages: '',
    };
  },
  methods: {
    resetCouponCode() {
      this.$emit('input', '');
      this.$emit('onDiscountValueChanged', 0);
      this.$store.commit('setCouponCode', '');
    },
    applyCoupon() {
      this.$api.post('/coupons/query', {
        code: this.code,
        duration: this.duration,
        hotelId: this.hotelId,
        roomId: this.roomId,
        checkinDate: moment(this.checkinDate).utcOffset(0, true).toISOString(true),
      }).then((res) => {
        this.$emit('input', this.code);
        this.$store.commit('setCouponCode', this.code);
        this.$emit('onDiscountValueChanged', res.data.discount);
      }).catch(() => {
        this.errorMessages = 'Coupon code does not exist or has expired';
        this.resetCouponCode();
      });
    },
  },
  watch: {
    code: _.debounce(function (_code) {
      this.errorMessages = '';
      if (_code.length === 8) {
        this.applyCoupon();
      } else {
        this.resetCouponCode();
        if (_code.length > 0) {
          this.errorMessages = 'Coupon code is invalid';
        }
      }
    }, 500),
  },
};
</script>

<style lang="scss" scoped>
.field {
  span {
    font-size: 18px;
  }
}
</style>
